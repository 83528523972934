import React from 'react';

const HeaderPage = (props) => {
    const { srcImage, title } = props;

    return (
        <header className="page-header fade">
            <img className="img" src={srcImage} alt={title} />

            <div className="content">
                {
                    title ? <h1>{title}</h1> :
                        // <>
                        //     <h1>Radio Ain Karim</h1>
                        //     <p>przy parafii Nawiedzenia NMP w Skomielnej Czarnej</p>
                        //     <span>104.4 FM</span>
                        // </>
                        <>
                            <h1>Transmisja na żywo</h1>
                            <p>parafia Nawiedzenia NMP w Skomielnej Czarnej</p>
                            <a href='/transmisja-tv' title="Transmisja TV" className="btn btn-2 block-center">Przejdź do transmisji</a>
                        </>
                }
            </div>
        </header>
    );
}

export default HeaderPage;