import React from 'react';
import {Helmet} from 'react-helmet';
import HeaderPage from '../components/HeaderPage';

import image from '../images/wolontariusze2.jpg';

const Volunteers = () => {
    return (
        <>
            <Helmet>
                <title>Wolontariusze - Radio Ain Karim</title>
                <meta name="description" content="Katolickie radio działające przy parafii pw. Nawiedzenia NMP w Skomielnej Czarnej. Na antenie radia program stanowią refleksje, modlitwa i katecheza chrześcijańska, część czasu wypełnia jednak także muzyka - religijna i świecka. Rozgłośnia przełącza się na retransmisję programów innych, większych stacji diecezjalnych." />
            </Helmet>
            <HeaderPage title="Wolontariusze" srcImage={image} />

            <section className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <h2 className="section-header">Nasi <strong>wolontariusze</strong></h2>
                            <p>
                                Radio to nie tylko dźwięki, muzyka i audycje – to przede wszystkim ludzie. To właśnie dzięki zaangażowaniu naszych niezwykłych wolontariuszy radio tętni życiem, inspiruje i łączy słuchaczy.
                                <br/>
                                To oni stoją za mikrofonem, przygotowują audycje, dzielą się swoją pasją i wiedzą. To oni promują naszą działalność, dbają o techniczną stronę emisji, organizują wydarzenia i wspierają radio na niezliczone sposoby. Ich energia, kreatywność i poświęcenie sprawiają, że radio jest czymś więcej niż tylko falą dźwięku – jest wspólnotą ludzi, którzy kochają to, co robią.
                                <br/>
                                Działamy razem – z pasją, sercem i zaangażowaniem. A jeśli chcesz do nas dołączyć i stać się częścią tej wyjątkowej ekipy, zawsze znajdzie się dla Ciebie miejsce!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Volunteers;