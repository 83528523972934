import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Loader from '../components/Loader';


class TVBroadcast extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(!this.props.AinkarimTV.length) {
            return
        }
    }

    render() {
        const { title, content } = this.props.AinkarimTV[0];
    
        return (
            <>
                <Helmet>
                    <title>{title.rendered} - Radio Ain Karim</title>
                    <meta name="description" content="Katolickie radio działające przy parafii pw. Nawiedzenia NMP w Skomielnej Czarnej. Na antenie radia program stanowią refleksje, modlitwa i katecheza chrześcijańska, część czasu wypełnia jednak także muzyka - religijna i świecka. Rozgłośnia przełącza się na retransmisję programów innych, większych stacji diecezjalnych." />
                </Helmet>
                
                <section className="section-padding">
                    <div className="container ainkarim-tv">
                    {!this.props.AinkarimTV.length ? <Loader /> : (
                                <div className="content">
                                    <h2 className="title">{title.rendered}</h2>

                                    <div dangerouslySetInnerHTML={{
                                        __html: content.rendered
                                    }} />
                                </div>
                            )}
                    </div>
                </section>
            </>
        );
    }
}

export default TVBroadcast;
