import React, { Component } from 'react';
import WpRadioAinKarim from '../apis/WpRadioAinKarim';


class RadioStreamProgramInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProgram: {},
            currentDay: '',
            currentTime: '',
            start: '',
            end: '',
            title: '',
        }
    }

    componentDidMount() {
        this.getCurrentDate();
        this.refresh = setInterval(this.checkProgram, 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentDay !== this.state.currentDay) {
            this.getProgram();
        }
    }

    componentWillUnmount() {
        clearInterval(this.refresh);
    }

    checkProgram = () => {
        this.getCurrentDate();
        this.getProgram();
    }

    convertHmsToSeconds(str) {
        var p = str.split(':'),
            s = 0, m = 1;
    
        while (p.length > 0) {
            s += m * parseInt(p.pop(), 10);
            m *= 60;
        }
    
        return s;
    }

    getCurrentDate = () => {
        const date = new Date();
        const currentTime = (date.getHours() * 60 * 60) + (date.getMinutes() * 60) + date.getSeconds();
        const currentDay = date.getUTCDay();

        this.setState({
            currentDay,
            currentTime,
        });
    }

    getProgram = async () => {
        const resp = await WpRadioAinKarim.get('/pages/9', {
            params: {
              status: 'publish'
            }
          });

        const program = resp.data.acf.program.find(item => this.state.currentDay !== 0 ? item.day_id === String(this.state.currentDay) : item.day_id === '7')

        this.getCurrentProgram(program.program_list);
    }

    getCurrentProgram = (program) => {
        const { currentTime } = this.state;

        const currentProgram = [...program].find(item => {
            return this.convertHmsToSeconds(item.start_time) <= currentTime && this.convertHmsToSeconds(item.end_time) >= currentTime
        });

        if(!currentProgram) {
            return
        }

        this.setState({
            currentProgram,
            title: currentProgram.title,
            start: currentProgram.start_time,
            end: currentProgram.end_time,
        });
    }
    
    render() {
        const { currentProgram: { start_time, end_time, title } } = this.state;

        return (
            <span>
                {start_time && end_time && `${start_time.slice(0, -3)}-${end_time.slice(0, -3)} `}
                {title ? (
                    title.length > 36 ? `${title.slice(0, 36)}...` : title
                ) : 
                    "Brak danych"
                }
            </span>
        );
    }
}

export default RadioStreamProgramInfo;