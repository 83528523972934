import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../styles/App.scss';
import RadioStream from '../layouts/RadioStream';
import Navigation from '../layouts/Navigation';
import Page from '../layouts/Page';
import Footer from '../layouts/Footer';
import Loader from '../components/Loader';
import WpRadioAinKarim from '../apis/WpRadioAinKarim';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      posts: [],
      news: [],
      archiveAuditions: [],
      reports: [],
      partners: [],
      about: [],
      ainkarimTV: [],
      concert: [],
      donate: [],
      privacyPolicy: [],
    }
  }

  componentDidMount() {
    this.getPosts();
    this.getPages();
  }

  componentDidUpdate(prevProps, prevState) {
    // Wyrenderuj się jeśli wszyskie dane będą pobrane
    if (this.state.posts.length === prevState.posts.length && this.state.about.length !== prevState.about.length)
      this.setState({ loaded: true });

  }

  getPosts = async () => {

    const resp = await WpRadioAinKarim.get('/posts', {
      params: {
        per_page: '100',
        status: 'publish',
        categories: [5, 7, 4]
      }
    });

    const news = resp.data.filter(item => item.categories.indexOf(5) !== -1);
    const archiveAuditions = resp.data.filter(item => item.categories.indexOf(7) !== -1);
    const reports = resp.data.filter(item => item.categories.indexOf(4) !== -1);

    const posts = resp.data;

    this.setState({
      posts,
      news,
      archiveAuditions,
      reports
    });
  }

  getPages = async () => {
    const resp = await WpRadioAinKarim.get('/pages', {
      params: {
        per_page: 20,
        status: 'publish'
      }
    });

    const about = resp.data.filter(item => item.id === 7);
    const concert = resp.data.filter(item => item.id === 15);
    const donate = resp.data.filter(item => item.id === 13);
    const privacyPolicy = resp.data.filter(item => item.id === 81);
    const ainkarimTV = resp.data.filter(item => item.id === 2480);

    this.setState({
      about,
      concert,
      donate,
      privacyPolicy,
      ainkarimTV,
    });
  }

  render() {
    const { news, loaded } = this.state;

    return (
      <>
        <RadioStream />

        <Router>
          {!loaded ? <Loader /> : (
            <div className="App">
              <Navigation />
              <Page data={this.state} />
              <Footer news={news} />
            </div>
          )}

        </Router>
      </>
    );
  }
}

export default App;
