import React, { Component } from 'react';


import logo1 from '../images/partners/kapucyni_pl_logo.png';
import logo2 from '../images/partners/dzielo_pomocy_logo.png';
import logo3 from '../images/partners/kapucyni_misje.png';
import logo4 from '../images/partners/archidiecezja_krakowska_logo.png';
import logo5 from '../images/partners/gokis_tokarnia.jpg';
import logo6 from '../images/partners/salvatti_logo.png';
import logo7 from '../images/partners/orlen.png';
import logo8 from '../images/partners/radoiokatolickie_logo.png';
import logo9 from '../images/partners/radio_jasnagora_logo.png';
import logo10 from '../images/partners/radio_profeto_logo.png';
import logo11 from '../images/partners/itv_mylenice_logo.png';
import logo12 from '../images/partners/gmina_tokarnia_logo.png';


const partnersArr = [
    { "name": "PKN Orlen", "source_url": logo7, "alt": "radio-nadaje", "site_url": "https://www.orlen.pl/" },
    { "name": "Kapucyni.pl", "source_url": logo1, "alt": "kapucyni-pl", "site_url": "https://kapucyni.pl/" },
    { "name": "Fundacja kapucyni i misje", "source_url": logo3, "alt": "fundacja-kapucyni-misje", "site_url": "https://fundacja.kapucyni.pl/" },
    { "name": "Dzieło Pomocy św. Ojca Pio", "source_url": logo2, "alt": "dzielo-pomocy-sw-ojca-pio", "site_url": "http://dzielopomocy.pl/" },
    { "name": "Archidiecezja Krakowska", "source_url": logo4, "alt": "archidiecezja-krakowska", "site_url": "https://diecezja.pl/" },
    { "name": "Pallotine Missionary Fundation Salvatti.pl", "source_url": logo6, "alt": "salvatti-pl", "site_url": "http://salvatti.pl/en/pallotine-missionary-foundation-salvatti-pl/" },
    { "name": "Radio Katolickie", "source_url": logo8, "alt": "radio-katolickie", "site_url": "http://radiokatolickie.pl/" },
    { "name": "Radio Jasna Góra", "source_url": logo9, "alt": "radio-jasna-gora", "site_url": "http://radiojasnagora.pl/" },
    { "name": "Radio Profeto", "source_url": logo10, "alt": "radio-profeto", "site_url": "http://radioprofeto.pl/" },
    { "name": "Myślenice iTV", "source_url": logo11, "alt": "myslenice-itv", "site_url": "https://www.myslenice-itv.pl/" },
    { "name": "Gmina Tokarnia", "source_url": logo12, "alt": "gmina-tokarnia", "site_url": "http://tokarnia.info.pl/php/index.php" },
    { "name": "GOKiS Tokarnia", "source_url": logo5, "alt": "gokis-tokarnia", "site_url": "http://domkultury.manifo.com/" },
];



const OurPartnersItems = (props) => {
    const { sourceUrl, alt, siteUrl, name } = props;

    return (
        <div className="partner-item">
            <a href={siteUrl} title={name} target="_blank" rel="noopener noreferrer">
                <img src={sourceUrl} alt={alt} />
            </a>
        </div>
    );
}


class OurPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countSlide: Math.ceil(partnersArr.length / this.props.countIcons),
            slideNumber: 1,
            firstSlide: 0,
            lastSlide: this.props.countIcons - 1
        }
    }

    static defaultProps = {
        countIcons: 4
    }

    componentDidMount() {
        this.indexInterval = setInterval(() => {
            let slideNumber = this.state.slideNumber;
            let firstSlide, lastSlide;

            if(slideNumber < this.state.countSlide) {
                firstSlide = this.state.lastSlide + 1;
                lastSlide = (slideNumber + 1) === this.state.countSlide ? partnersArr.length - 1 : firstSlide + this.props.countIcons - 1;
                slideNumber++;
            } else {
                firstSlide = 0;
                lastSlide = this.props.countIcons - 1;
                slideNumber = 1;
            }

            this.setState({
                slideNumber,
                firstSlide,
                lastSlide
            });
        }, 8000)
    }
    
    componentWillUnmount() {
        clearInterval(this.indexInterval);
    }
    
    render() {
        const partners = partnersArr.slice(this.state.firstSlide, this.state.lastSlide+1).map((partner, index) =>
            <OurPartnersItems
                key={index}
                name={partner.name}
                sourceUrl={partner.source_url}
                siteUrl={partner.site_url}
                alt={partner.alt}
                index={index}
            />);

        return (
            <section className="section-padding">
                <div className="container">
                    <h2 className="section-header">Nasi <strong>partnerzy</strong></h2>
                    <div id="partner-items" className="partner-items">
                        {partners}
                    </div>
                </div>
            </section>
        );
    }
}

export default OurPartners;