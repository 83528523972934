import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavElement from '../components/NavElement';
// import logo from '../images/logoRadioAinKarim.png';
import logo from '../images/logo.svg';
import PropTypes from "prop-types";
import { withRouter } from "react-router";


const navItems = [
    { link: "/transmisja-tv", title: "Transmisja TV", openNewWindow: false },
    { link: "/o-radiu", title: "O radiu", openNewWindow: false },
    { link: "/ramowka", title: "Ramówka", openNewWindow: false },
    { link: "/aktualnosci", title: "Aktualności", openNewWindow: false },
    { link: "/audycje", title: "Audycje", target: '_self' },
    { link: "/wolontariusze", title: "Wolontariusze", openNewWindow: false },
    { link: "https://www.skomielnaczarna.kapucyni.pl/", title: "Parafia", openNewWindow: true },
    // { link: "/zarzad", title: "Zarząd", openNewWindow: false },
    { link: "/kontakt", title: "Kontakt", openNewWindow: false },
]

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.sticky = document.getElementById('sticky-js');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname)
            this.closeMenu();
    }

    closeMenu = () => {
        const icon = document.querySelector('.menu-icon');
        const nav = icon.parentNode.nextElementSibling;

        icon.classList.remove('change');
        nav.classList.remove('active');
    }

    handleClick = (e) => {
        const navList = document.querySelector('.nav-list'); 
        navList.classList.toggle('active');

        const icons = document.querySelectorAll('.menu-icon');

        if(navList.classList.contains('active')) {
            for(const icon of icons) {
                icon.classList.add('change');
            }
        } else {
            for(const icon of icons) {
                icon.classList.remove('change');
            }
        }
    };

    render() {
        const navLinks = navItems.map((item, index) =>
            <NavElement
                key={index}
                navItems={item}
            />)

        return (
            <nav className="main-nav" id="sticky-js">

                <div className="container">
                    <div className="mobile">
                        <Link className="nav-link" to="/" title="Strona główna">
                            <img src={logo} alt="Radio Ain Karim" />
                        </Link>
                        <div className="menu-icon btn-nav-open" onClick={this.handleClick}>
                            <div className="icon bar1"></div>
                            <div className="icon bar2"></div>
                            <div className="icon bar3"></div>
                        </div>
                    </div>
                    <ul className="nav-list">
                        <div className="menu-icon btn-nav-open" onClick={this.handleClick}>
                            <div className="icon bar1"></div>
                            <div className="icon bar2"></div>
                            <div className="icon bar3"></div>
                        </div>
                        <li className="nav-list-item">
                            <Link className="nav-list-item-link" to="/" title="Strona główna">
                                <img src={logo} className="nav-list-item-logo" alt="Logo Radia Ain Karim" />
                            </Link>
                        </li>
                        {navLinks}
                    </ul>
                </div>

            </nav>
        );
    }
}

export default withRouter(Navigation);