import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from './Loader';

class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            isEnd: false,
            isHours: false,
            progressMode: false,
            progress: 0,
        }

        this.progressBar = React.createRef();
    }

    componentDidMount() {
        const {record_file, record_url} = this.props.audio;
        const record = record_file ? record_file : 'https://api.radioainkarim.pl/wp-content/uploads/nagrania' + record_url;
        this.audio = new Audio(record);

        this.audio.addEventListener('loadeddata', () => {
            this.setState({
                isHours: Math.floor(this.audio.duration / 3600),
                duration: this.audio.duration,
            });
        });

        this.intervalId = setInterval(this.handleUpdateCurrentTime, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isPlaying !== this.state.isPlaying)
            this.state.isPlaying ? this.audio.play() : this.audio.pause();

    }

    componentWillUnmount() {
        this.audio.pause();
        clearInterval(this.intervalId);
    }

    handlePlay = () => {
        this.setState({ isPlaying: !this.state.isPlaying });

        if (this.state.isEnd) {
            this.audio.currentTime = 0;

            this.setState({
                isEnd: false,
                isPlaying: false,
                progress: 0
            });
        }
    }

    handleUpdateCurrentTime = () => {
        if (this.audio && !this.audio.paused)
            this.setState({ progress: this.audio.currentTime / this.audio.duration });

        if (this.audio.ended) {
            this.setState({ isEnd: true });
        }
    }

    handleStartProgress = (e) => {
        this.setState({ progressMode: true });
        this.handleSetProgress(e);
    }

    handleSetProgress = (e) => {
        if (this.state.progressMode) {
            let progress = (e.clientX - this.offsetLeft(this.progressBar.current)) / this.progressBar.current.clientWidth;

            this.audio.currentTime = this.audio.duration * progress;

            this.setState({ progress });
        }
    }

    handleStopProgress = (e) => {
        this.setState({ progressMode: false });
        this.handleSetProgress(e);
    }

    formatTime = (time) => {
        // if(!time) return "--";

        if (time) {
            let hours = Math.floor(time / 3600);
            let minutes = Math.floor((time - hours * 3600) / 60);
            let seconds = Math.floor(time - (hours * 3600 + minutes * 60));

            hours = hours < 10 ? `0${hours}` : hours;
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            seconds = seconds < 10 ? `0${seconds}` : seconds;

            if (this.state.isHours) return `${hours}:${minutes}:${seconds}`;
            else return `${minutes}:${seconds}`;
        } else return '00:00'
    }

    offsetLeft = (elem) => {
        let left = 0;

        while (elem && elem !== document) {
            left += elem.offsetLeft;
            elem = elem.offsetParent;
        }

        return left;
    }


    render() {

        const replyBtn = (
            <button
                title="Kliknij by posłuchać nagrania"
                onClick={this.handlePlay}
                className="button" >
                <FontAwesomeIcon icon="reply" />
            </button>
        );

        const playBtn = (
            <button
                title="Kliknij by posłuchać nagrania"
                onClick={this.handlePlay}
                className="button" >
                <div className="play" style={{ borderLeftColor: `black` }}></div>
            </button>
        );

        const pauseBtn = (
            <button
                title="Kliknij by posłuchać nagrania"
                onClick={this.handlePlay}
                className="button" >
                <div className="pause" style={{ borderColor: `black` }}></div>
            </button>
        );

        const {record_title, record_author} = this.props.audio;


        return (
            <>
                {!this.audio ? <Loader /> : (
                    <div className="player-wraper">
                        <div className="player-info">
                            <h4 className="player-info-title">{record_title}</h4>
                            <h5 className="player-info-author">{record_author}</h5>
                        </div>
                        
                        <div className="player">
                            {this.state.isEnd ?
                                replyBtn : (
                                    !this.state.isPlaying ? playBtn : pauseBtn
                                )
                            }

                            <div className="player-time-info">
                                {this.formatTime(this.audio.currentTime)}
                            </div>

                            <div
                                className="progress-bar"
                                ref={this.progressBar}
                                onMouseDown={this.handleStartProgress}
                                onMouseMove={this.handleSetProgress}
                                onMouseLeave={this.handleStopProgress}
                                onMouseUp={this.handleStopProgress}
                            >
                                <div className="progress" style={{ width: `${this.state.progress * 100}%` }}></div>
                                <div className="bar"></div>
                                <div className="time-handle"></div>
                                <div className="time-float"></div>
                            </div>

                            <div className="player-time-info">
                                {this.formatTime(this.audio.duration)}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default Player;